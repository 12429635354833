import { Flex, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { MenuProps } from "../../../types/MenuProps";
import Logo from "../../atoms/Logo";
import MenuToggle from "../../atoms/MenuToggle";
import MenuLinks from "../../molecules/MenuLinks";

const NavBar = (props: MenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle: () => void = () => setIsOpen(!isOpen);

  return (
    <Flex
      as="nav"
      bgGradient="linear(to-b, primary.500, primary.500, transparent)"
      w="100%"
      justify={"center"}
      mb={8}
      py={8}
    >
      <Flex w="100%" maxWidth={"85vw"} direction={"column"}>
        <Flex
          direction={"row"}
          justify="flex-end"
          color={"gray.100"}
          display={{ base: "none", lg: "flex" }}
        >
          <Heading size={"lg"}>¿Necesitas ayuda?</Heading>
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          color={"gray.100"}
          gap={6}
          {...props}
        >
          {isOpen ? (
            <></>
          ) : (
            <Stack
              direction={"row"}
              align="center"
              justify="stretch"
              display={{ base: "center", lg: "flex" }}
            >
              <Logo color={"gray.100"} />{" "}
              <Text fontWeight={"semibold"}>Administraciones</Text>
            </Stack>
          )}

          <MenuToggle toggle={toggle} isOpen={isOpen} />
          <MenuLinks isOpen={isOpen} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavBar;
