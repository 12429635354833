import { Flex, Heading, Text } from "@chakra-ui/react";
import { InformativeSectionProps } from "../../../types/InformativeSectionProps";

const InformativeSection = ({
  // image,
  title,
  // alt,
  description,
}: InformativeSectionProps) => {
  return (
    <Flex flexDirection={"column"} flex={1} gap={8}>
      <Heading textAlign={"center"}>{title}</Heading>
      <Text>{description}</Text>
    </Flex>
  );
};
export default InformativeSection;
