// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { defineStyleConfig } from "@chakra-ui/react";

const Link = defineStyleConfig({
  baseStyle: {
    fontWeight: "semibold",
    fontFamily: "heading",
  },
  variants: {
    button: {
      color: "gray.100",
      _hover: { color: "secondary.500", transform: "scale(1.03)" },
    },
    menu: {
      fontSize: "md",
      color: "gray.100",
      _hover: {
        color: "primary.200",
        transform: "scale(1.03),",
        textDecoration: "none",
      },
    },
  },
  // 6. We can overwrite defaultProps
  defaultProps: {
    size: "lg", // default is md
    variant: "button", // default is solid
    colorScheme: "secondary", // default is gray
  },
});

export default Link;
