import { Flex, Image } from "@chakra-ui/react";
import { InformativeSectionProps } from "../../../types/InformativeSectionProps";
import InformativeSection from "../../molecules/InformativeSection";

interface InformativeSectionContainerProps {
  variant: string;
  section1: InformativeSectionProps;
  imageUrl?: string;
  section2?: InformativeSectionProps;
}

const getFlexDirection = (variant: string) => {
  if (variant === "left-image") return "row";
  if (variant === "right-image") return "row-reverse";
  return "row";
};

const InformativeSectionContainer = ({
  variant,
  imageUrl,
  section1,
  section2,
}: InformativeSectionContainerProps) => {
  return (
    <Flex
      direction={["column", "column", "column", getFlexDirection(variant)]}
      justify="space-between"
      align="center"
      w={"100%"}
      gap={10}
    >
      {variant === "double-section" ? (
        <>
          <InformativeSection
            title={section1.title}
            description={section1.description}
          />
          {section2 && (
            <InformativeSection
              title={section2.title}
              description={section2.description}
            />
          )}
        </>
      ) : (
        <>
          {imageUrl && (
            <Flex flex={1} p={4} w={["289px", "326px", "400px", "400px"]}>
              <Image
                src={imageUrl}
                alt={section1.title}
                borderRadius="10"
                objectFit="contain"
                boxSize={"-moz-fit-content"}
              />
            </Flex>
          )}
          <InformativeSection
            title={section1.title}
            description={section1.description}
          />
        </>
      )}
    </Flex>
  );
};
export default InformativeSectionContainer;
