import { Box, Button, Stack } from "@chakra-ui/react";
import { MenuProps } from "../../../types/MenuProps";
import MenuItem from "../../atoms/MenuItem";

const menuItems = [
  { to: "/", text: "Home" },
  { to: "/nosotros", text: "Nosotros" },
  { to: "/mejoras", text: "Mejoras" },
  { to: "/contacto", text: "Contacto" },
  {
    to: "https://forms.gle/ynBerfiVpTfy9HrQ8",
    text: "Solicita una propuesta",
    isLast: true,
    variant: "secondary-solid",
  },
  {
    to: "https://forms.gle/bG5KTv7jeA7R72tP6",
    text: "Tickets",
    isLast: true,
    variant: "outline",
  },
];

const MenuLinks: React.FC<MenuProps> = ({ isOpen }) => {
  return (
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
    >
      <Stack
        spacing={3}
        align="center"
        justify={["center", "space-between", "flex-end", "flex-end"]}
        direction={["column", "column", "row", "row"]}
        wrap="wrap"
        pt={[4, 4, 0, 0]}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            to={item.to}
            isLast={item.isLast}
            isExternal={item.isLast}
          >
            {item.isLast ? (
              <Button size="sm" rounded="md" variant={item.variant}>
                {item.text}
              </Button>
            ) : (
              item.text
            )}
          </MenuItem>
        ))}
      </Stack>
    </Box>
  );
};

export default MenuLinks;
