import { Card, CardBody, Center, Heading, Image, Text } from "@chakra-ui/react";
import { InformativeCardProps } from "../../../types/InformativeCardProps";

const PhotoCard = ({ image, title, description }: InformativeCardProps) => {
  return (
    <Card
      flexDirection={"column"}
      w={["289px", "326px", "326px", "326px"]}
      borderRadius={10}
      bg={"secondary.100"}
    >
      <Center>
        <Image
          src={image}
          alt={title}
          borderRadius={10}
          objectFit="cover"
          h={"326px"}
          w={"100%"}
        />
      </Center>

      <CardBody>
        <Heading fontSize="sm" textAlign={"center"} color={"text.primary"}>
          {title}
        </Heading>
        <Text color={"text.primary"}>{description}</Text>
      </CardBody>
    </Card>
  );
};
export default PhotoCard;
